import {extractQueryParams} from "../../Helpers/extractQueryParams";
import {IInitParams, StorageKeys} from "../../services";
import isEmpty from "lodash/isEmpty";
import React, {useState} from "react";
import {useLocation} from "react-router";
import {IToggleProps} from "./featureToggle/withToggle";

const withIncomeInsuranceParams = <P extends IToggleProps>(dependencies: any) => {
    return (WrappedComponent: React.ComponentType<P>) => (props: P) => {
        const location = useLocation();
        useState(() => {
            initIncomeInsuranceParams({...dependencies, location});
            return true;
        });

        return <WrappedComponent {...props}/>;
    }
};

const initIncomeInsuranceParams = ({sessionStorage, location}: any): void => {
    const params = extractQueryParams(["id"], location.search) as IInitParams;

    if (params?.id) {
        const parts = params?.id?.split("-");
        params.id = parts[0];
        params.commonGroupId = parts[0];
        params.type = parts[1] as "TILLAGGLON" | "TILLAGGTID";
    }

    if (!isEmpty(params)) {
        sessionStorage.set(StorageKeys.PARAMS, params);
    }
}

export {withIncomeInsuranceParams};
