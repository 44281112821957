import moment from "moment"
import {JourneyBase, Cover, Constants, CoverPlan, Discount} from "@folksam-digital/model";
import {
    BaseAnalytics,
    IOnTriggerDataLayerEventParams, JourneyType, PageTypes,
    SatelliteEvents
} from "./BaseAnalytics";
import {StorageKeys} from "../../services";
import uniq from "lodash/uniq";
import {FeatureToggleUtils, PbbHelper} from "@folksam-digital/services";
import {productAnalyticsCodes, unionAnalyticsCodes} from "./AnalyticsHelper";

export interface ProductMapping {
    productId: Array<string>;
    productUnits?: Array<number>;
    productPrice?: Array<number>;
    discount?: string[];
    transactionId: string;
    agentId?: string;
    union?: string;
    insuranceAmount?: number;
    paymentMethod?: string;
    paymentPeriod?: string;
    itemYear?: string;
    residents?: number;
    area?: number;
    address?: "Ja" | "Nej";
    milage?: string;
    prevInsurance?: string;
    partner?:string;
}
interface IProductData {
    productId: string[];
    productUnits: number[];
    productPrice: number[];
}

interface ICustomProperties {
    dataLayerObj: ProductMapping;
    journeyId?: string;
    data?: JourneyBase<any>
}

interface IProductAddonAdd {
    productIds: string[];
    addon?: Cover;
    productCode: string;
}

interface IProductCoverPlanAdd {
    productIds: string[];
    coverPlan?: string;
    productCode: string;
}

const accidentAndIllnessProductLineGroup: number = 1000157;

export class ProductAnalytics extends BaseAnalytics {
    public async onTriggerDataLayerEvent(params: IOnTriggerDataLayerEventParams): Promise<void> {
        const {data, messages, journeyId} = params;
        let productData: IProductData = {} as IProductData;

        if (data) {
            productData = this.mapProductIdToAnalyticsCode(data, journeyId!);
        }

        let dataLayerObj: ProductMapping = {
            productId: productData.productId,
            transactionId: this.getTransactionId(),
        };

        if(data?.policy?.premium?.discounts?.length > 0){
            dataLayerObj.discount = uniq(data?.policy?.premium?.discounts?.map((discount: Discount) => discount.reason));
        }
        if(productData.productPrice?.every(price => price !== undefined) && productData.productPrice.length > 0){
            dataLayerObj.productPrice = productData.productPrice;
        }
        if(productData.productUnits && productData.productUnits.length > 0){
            dataLayerObj.productUnits = productData.productUnits;
        }
        if(data?.policy?.insuredObject?.memberOfUnion || data?.policy?.insuredObject?.group || data?.policy?.group){
           dataLayerObj.union = this.mapUnionToAnalyticsCode(data?.policy);
        }
        if(data?.policy?.premium?.coverPlans && this.getInsuranceAmount(params) !== 0 ){
            dataLayerObj.insuranceAmount = this.getInsuranceAmount(params);
        }
        if (data?.policy?.paymentDetails?.paymentMethod) {
            dataLayerObj.paymentMethod = messages[`general.adobe.product.paymentDetails.paymentMethod.${data?.policy?.paymentDetails?.paymentMethod}`] ?? "";
        }
        if (data?.policy?.paymentDetails?.paymentFrequency) {
            dataLayerObj.paymentPeriod = messages[`general.adobe.product.paymentDetails.paymentFrequency.${data?.policy?.paymentDetails?.paymentFrequency}`] ?? "";
        }
        if (data?.policy?.insuredObject?.yearOfManufacture) {
            dataLayerObj.itemYear = data?.policy?.insuredObject?.yearOfManufacture ?? ""
        }
        if (data?.policy?.insuredObject?.birthDate) {
            dataLayerObj.itemYear = moment.utc(data.policy.insuredObject.birthDate).year().toString();
        }
        if (data?.policy?.insuredObject?.numberOfResidents) {
            dataLayerObj.residents = Number(data?.policy?.insuredObject?.numberOfResidents);
        }
        if (data?.policy?.insuredObject?.livingArea) {
            dataLayerObj.area = Number(data?.policy?.insuredObject?.livingArea);
        }
        if(data?.policy?.insuredObject?.address?.newAddress){
            dataLayerObj.address = this.mapAddressToAnalyticsCode(params);
        }
        if (data?.policy?.insuredObject?.drivingDistancePerYear) {
            dataLayerObj.milage = data?.policy?.insuredObject?.drivingDistancePerYear.label;
        }
        if (data?.policy?.insuredObject?.prevInsurance?.insured) {
            dataLayerObj.prevInsurance = data?.policy?.insuredObject?.prevInsurance?.placeOfIssue?.label;
        }

        if (params.data.policy.partner) {
            dataLayerObj.partner = params.data.policy.partner;
        }

        const customProperties: ICustomProperties = {
            dataLayerObj,
            journeyId: params?.journeyId,
            data: params?.data,
        };

        dataLayerObj = this.addCustomProperties(customProperties);

        if(this.getAgentId()){
            dataLayerObj.agentId = this.getAgentId();
        }

        let trackKey = this.getTrackingKey(`${params.transactionType}${params.event}`);

        if (params.event === SatelliteEvents.Step) {
            const nextStepIndex: number = params.currentStepIndex! + 1;
            trackKey = `${trackKey}${nextStepIndex}`;
        }

        if (params.currentStep === "error") {
            trackKey = PageTypes.SPAPage;
        }
        
        this.setDataLayer(dataLayerObj, trackKey, params, JourneyType.Product);
    }

    private mapUnionToAnalyticsCode(policy: any): string | undefined{
        const union = policy.insuredObject?.memberOfUnion ?? policy.insuredObject?.group?.name ?? policy.group?.name;
        const unionCode = unionAnalyticsCodes.find(unionObj => union === unionObj.name)?.value;

        return unionCode ?? undefined
    }

    private mapProductIdToAnalyticsCode(data: JourneyBase<any>, journeyId: string): IProductData {
        const productId = data.policy?.productId;
        const coverIds: (number | string)[] = [];
        const covers: Cover[] = data.policy?.covers;
        let optionNumber: number | undefined;

        if (covers) {
            for (const cover of Object.values(covers)) {
                if (!cover?.selected || !cover?.id) {
                    continue;
                }

                // Can be improved
                // If cover plan is Traffic, ignore Assistance cover
                if (journeyId === Constants.Journey.MobileHome.Id && data?.policy.coverPlan === Constants.CoverPlan.MobileHome.Traffic) {
                    continue;
                }

                coverIds.push(cover.id);
            }
        }

        if (data.policy?.coverPlan) {
            coverIds.push(data.policy.coverPlan);
        }

        const coverPlan = data.policy?.premium?.coverPlans?.find((row: CoverPlan) => row.id === data?.policy?.coverPlan);
        const mapOptionNumber = () => {
            for (const cover of coverPlan.covers as Cover[]) {
                if (cover?.id !== accidentAndIllnessProductLineGroup) {
                    continue;
                }
                optionNumber = cover.optionNumber;
            }
        }
        switch (journeyId) {
            case Constants.Journey.Accident.Id:
                if (coverPlan?.covers) {
                    mapOptionNumber();
                } else {
                    optionNumber = data?.contact?.age >= 65 ? 3 : 1;
                }
                break;
            case Constants.Journey.AccidentAndIllness.Id:
                if (coverPlan?.covers) {
                    mapOptionNumber();
                } else {
                    optionNumber = data?.contact?.age >= 65 ? undefined : 2;
                }
                break;
        }

        const mappedProductId: string[] = [];
        const mappedProductUnits: number[] = [];
        const mappedProductPrice: number[] = [];

        coverIds.forEach((element: number | string) => {
            let id: string = `${productId}_${element}`;
            if (optionNumber) {
                id = `${id}_${optionNumber}_${journeyId}`;
            }

            if (productAnalyticsCodes[id]) {
                if (element.toString() === Constants.CoverPlan.MemberChild.Large
                    || element.toString() === Constants.CoverPlan.MemberChild.Medium) {
                    mappedProductId.push(`${productAnalyticsCodes[id]}_${element.toString().substring(0, element.toString().indexOf("_0"))}`);
                } else {
                    mappedProductId.push(`${productAnalyticsCodes[id]}_${element}`);
                }
            } else {
                mappedProductId.push(id);
            }

            mappedProductUnits.push(1);
            mappedProductPrice.push(this.mapProductPrice(data, element));
        });

        return {
            productId: mappedProductId,
            productUnits: mappedProductUnits,
            productPrice: mappedProductPrice
        };
    }

    private mapProductPrice(data: JourneyBase<any>, coverId: number | string) {
        const coverPlan = data.policy?.premium?.coverPlans?.find((row: CoverPlan) => row.id === coverId);

        if (coverPlan) {
            return coverPlan?.yearlyPremium;
        } else if (data.policy.covers && data.policy.premium && data.policy.premium.covers) {
            for (const cover of data.policy.premium.covers as Cover[]) {
                if (cover.id === coverId) {
                    return cover.yearlyPremium;
                }
            }
        }
    }

    private getInsuranceAmount(params: IOnTriggerDataLayerEventParams): number{
        let mainCoverId: number = 0;

        switch (params?.journeyId) {
            case Constants.Journey.Accident.Id:
            case Constants.Journey.AccidentAndIllness.Id:
                mainCoverId = Constants.Product.AccidentAndIllness.mainCoverId;
                break;
            case Constants.Journey.IndividualChild.Id:
                mainCoverId = Constants.Product.IndividualChild.mainCoverId;
                break;
            case Constants.Journey.MemberChild.Id:
                mainCoverId = Constants.Product.MemberChild.mainCoverId;
                break;
        }

        return PbbHelper.getCoverPlanDetails(params.data, params.data?.policy?.coverPlan, mainCoverId).insuranceAmount;     
    }

    private mapAddressToAnalyticsCode(params: IOnTriggerDataLayerEventParams): "Ja" | "Nej"{
        if (params.journeyId === Constants.Journey.HomeVacation.Id) {
            // newAddress question/answer reversed
            return params.data?.policy?.insuredObject?.address?.newAddress === true ? "Ja" : "Nej";
        }
        
        return params.data?.policy?.insuredObject?.address?.newAddress === false ? "Ja" : "Nej"; 
    }

    private getAgentId(): string | undefined {
        const sessionParams: any = this.sessionStorage.get(StorageKeys.PARAMS);
        return sessionParams?.agentId;
    }

    private isCoopEnabled(data?: JourneyBase<any>, journeyId?: string): boolean {
        switch (journeyId) {
            case Constants.Journey.Car.Id:
            case Constants.Journey.Cat.Id:
            case Constants.Journey.Dog.Id:
            case Constants.Journey.HomeRental.Id:
            case Constants.Journey.HomeApartment.Id:
            case Constants.Journey.HomeBuilding.Id:
            case Constants.Journey.HomeStudent.Id:
            case Constants.Journey.HomeVacation.Id:
                if (FeatureToggleUtils.isFeatureEnabled("coop") && data?.policy?.partner === Constants.Partners.Coop) {
                    return true;
                }
        }
        return false;
    }

    private addCustomProperties(customProperties: ICustomProperties): ProductMapping {
        const {journeyId, data, dataLayerObj} = customProperties;

        switch (journeyId) {
            case Constants.Journey.Trailer.Id:
            case Constants.Journey.Caravan.Id:
            case Constants.Journey.LightMotorcycle.Id:
            case Constants.Journey.SmallTractor.Id:
            case Constants.Journey.HeavyMotorcycle.Id:
            case Constants.Journey.Moped.Id:
            case Constants.Journey.SnowmobileAtv.Id:
            case Constants.Journey.MobileHome.Id:
            case Constants.Journey.EpaAndATractor.Id:
            case Constants.Journey.MopedCar.Id:
            case Constants.Journey.LightTruck.Id:
            case Constants.Journey.Car.Id:
            case Constants.Journey.Cat.Id:
            case Constants.Journey.Dog.Id:
            case Constants.Journey.HomeRental.Id:
            case Constants.Journey.HomeApartment.Id:
            case Constants.Journey.HomeStudent.Id:
            case Constants.Journey.HomeBuilding.Id:
            case Constants.Journey.HomeVacation.Id:
            case Constants.Journey.HomeCondominium.Id:
                dataLayerObj.agentId = Constants.AgentId.Policy;
                dataLayerObj.productId = this.getProductId(journeyId, data);
                break;
            default:
                break;
        }

        return customProperties.dataLayerObj;
    }

    private getProductId(journeyId?: string, data?: JourneyBase<any>): string[] {
        let productIds: string[] = [];
        const policyCover = data?.policy?.covers;
        const policyCoverPlan = data?.policy?.coverPlan;
        const productCode = Constants.Analytics.LegacyProductCode[journeyId!];
        const homeProductCode = data?.policy?.insuredObject?.insuranceType === Constants.HomeGroupType.KHome ?
            Constants.Analytics.LegacyProductCode[`${journeyId}${Constants.HomeGroupType.KHome}`] : data?.policy?.productCode ?
                data.policy.productCode : productCode;

        switch (journeyId) {
            case Constants.Journey.SnowmobileAtv.Id:
                let snowMobilKey = "SNS" as keyof typeof Constants.Analytics.Prefix.SnowmobileAtv;

                if (data?.policy?.productCode) {
                    snowMobilKey = data?.policy?.productCode as keyof typeof Constants.Analytics.Prefix.SnowmobileAtv;
                }

                return this.createProductIdMessage(Constants.Analytics.Prefix.SnowmobileAtv[snowMobilKey], policyCoverPlan);
            case Constants.Journey.HeavyMotorcycle.Id:
                const key = `${journeyId}_${data?.policy?.insuranceType}`;
                return this.createProductIdMessage(Constants.Analytics.LegacyProductCode[key], policyCoverPlan);
            case Constants.Journey.MobileHome.Id:
                productIds = this.addProductAddon({productIds, addon: policyCover?.assistance, productCode});
                productIds = this.addProductCoverPlan({productIds, coverPlan: policyCoverPlan, productCode});

                return productIds;
            case Constants.Journey.Moped.Id:
                if (!data?.policy?.insuredObject?.registrationNumber) {
                    return this.createProductIdMessage(Constants.LegacyProductCodes.MopedWithoutRegistrationNumber, policyCoverPlan);
                }

                return this.createProductIdMessage(Constants.Analytics.LegacyProductCode[journeyId!], policyCoverPlan);
            case Constants.Journey.MopedCar.Id:
                if (!data?.policy?.insuredObject?.registrationNumber) {
                    return this.createProductIdMessage(Constants.LegacyProductCodes.MopedCarWithoutRegistrationNumber, policyCoverPlan);
                }

                return this.createProductIdMessage(Constants.Analytics.LegacyProductCode[journeyId!], policyCoverPlan);
            case Constants.Journey.Cat.Id:
                productIds = this.addProductAddon({productIds, addon: policyCover?.lifeInsurance, productCode});
                productIds = this.addProductAddon({productIds, addon: policyCover?.dentalDisease, productCode});

                productIds = this.addProductCoverPlan({productIds, coverPlan: policyCoverPlan, productCode});

                return productIds;
            case Constants.Journey.Dog.Id:
                productIds = this.addProductAddon({productIds, addon: policyCover?.lifeInsurance, productCode});
                productIds = this.addProductAddon({productIds, addon: policyCover?.breedingInsurance, productCode});

                productIds = this.addProductCoverPlan({productIds, coverPlan: policyCoverPlan, productCode});

                return productIds;
            case Constants.Journey.LightTruck.Id:
                let productCodeTruck = Constants.Analytics.Prefix.LightTruck;

                if (data?.policy?.insuredObject?.truckType) {
                    productCodeTruck = `${productCodeTruck}_${data.policy.insuredObject.truckType}`;
                }

                productIds = this.addProductAddon({productIds, addon: policyCover?.rentalCar, productCode: productCodeTruck});
                productIds = this.addProductAddon({productIds, addon: policyCover?.assistance, productCode: productCodeTruck});

                productIds = this.addProductCoverPlan({productIds, coverPlan: policyCoverPlan, productCode: productCodeTruck});

                return productIds;
            case Constants.Journey.Car.Id:
                const productCodeCar = `${data?.policy?.insuredObject?.brand}`;
                // car is exception to not have prodcutCode combined with addon.Id.
                productIds = this.addProductAddon({productIds, addon: policyCover?.rentalCar, productCode: ""});
                productIds = this.addProductAddon({productIds, addon: policyCover?.assistance, productCode: ""});

                productIds = this.addProductCoverPlan({productIds, coverPlan: policyCoverPlan, productCode: productCodeCar});

                return productIds;
            case Constants.Journey.HomeRental.Id:
            case Constants.Journey.HomeApartment.Id:
            case Constants.Journey.HomeBuilding.Id:
            case Constants.Journey.HomeCondominium.Id:
                productIds = this.addProductAddon({productIds, addon: policyCover?.travelInsurance, productCode: homeProductCode});
                productIds = this.addProductAddon({productIds, addon: policyCover?.golfInsurance, productCode: homeProductCode});
                productIds = this.addProductAddon({productIds, addon: policyCover?.smallBoatInsurance, productCode: homeProductCode});

                productIds = this.addProductCoverPlan({productIds, coverPlan: policyCoverPlan, productCode: homeProductCode});

                return productIds;
            case Constants.Journey.HomeStudent.Id:
                const revertAccidentInsuranceIdConcat = (accidentInsuranceCover: any, replacePattern: string) => {
                    if (accidentInsuranceCover?.id) {
                        accidentInsuranceCover.id = accidentInsuranceCover.id.replace(replacePattern, "")
                    }

                    return accidentInsuranceCover;
                };

                if (data?.targetEnv === Constants.TargetEnvironment.Idit) {
                    productIds = this.addProductAddon({
                        productIds,
                        addon: policyCover?.accidentInsurance,
                        productCode: homeProductCode
                    })
                } else {
                    productIds = this.addProductAddon({
                        productIds,
                        addon: policyCover?.accidentInsurance?.id?.includes("FT") ? revertAccidentInsuranceIdConcat(policyCover?.accidentInsurance, "FT") : policyCover?.accidentInsurance,
                        productCode: homeProductCode
                    });
                    productIds = this.addProductAddon({
                        productIds,
                        addon: policyCover?.accidentInsuranceRoomMate?.id?.includes("MF") ? revertAccidentInsuranceIdConcat(policyCover?.accidentInsuranceRoomMate, "MF") : policyCover?.accidentInsuranceRoomMate,
                        productCode: homeProductCode
                    });
                }

                productIds = this.addProductAddon({productIds, addon: policyCover?.travelInsurance, productCode: homeProductCode});

                productIds = this.addProductCoverPlan({productIds, coverPlan: policyCoverPlan, productCode: homeProductCode});

                if (data?.policy?.insuredObject?.numberOfResidents === 2) {
                    productIds?.push(`${homeProductCode}_MFO`);
                }

                return productIds;
            case Constants.Journey.EpaAndATractor.Id:
                productIds = this.addProductCoverPlan({productIds, coverPlan: policyCoverPlan, productCode: productCode});

                return productIds;
            case Constants.Journey.HomeVacation.Id:
                const homeVacationProductCode = data?.policy?.insuredObject?.wantsInsureThings === false ? Constants.LegacyProductCodes.HomeVacationNoInsuranceForThings : Constants.LegacyProductCodes.HomeVacation;
                productIds = this.addProductAddon({productIds, addon: policyCover?.smallBoatInsurance, productCode: homeVacationProductCode});

                productIds = this.addProductCoverPlan({productIds, coverPlan: policyCoverPlan, productCode: homeVacationProductCode});
                return productIds;
            default:
                return this.createProductIdMessage(Constants.Analytics.LegacyProductCode[journeyId!], policyCoverPlan);
        }
    }

    private addProductAddon({productIds, addon, productCode}: IProductAddonAdd): string[] {
        if (addon?.selected && addon?.available) {
            productIds.push(`${productCode}${productCode ? '_' : ''}${addon.id}`);
        }

        return productIds;
    }

    private createProductIdMessage(productCode: string, coverPlan: string | undefined): string[] {
        if (!coverPlan) {
            return [`${productCode}_${Constants.Analytics.Suffix.Prod}`];
        }

        return [`${productCode}_${coverPlan}_${Constants.Analytics.Suffix.Prod}`];
    }

    private addProductCoverPlan({productIds, coverPlan, productCode}: IProductCoverPlanAdd): string[] {
        if (coverPlan) {
            productIds.push(`${productCode}_${coverPlan}`);
        } else if (productCode) {
            productIds.push(productCode);
        }

        return productIds;
    }

}