import * as React from "react";
import {OutputComponentBase} from "./OutputComponentBase";
import {get, isEmpty} from "lodash";
import {IAnswer, Questionnaire, QuestionsAnswers} from "@folksam-digital/model";
import {RedirectControl} from "../controls/redirectControl";
import {DescriptionList, DescriptionListResponsive, Spacing, SummaryCard, withViewContext} from "@folksam-digital/ui";
import {Template} from "./helpers";
import {withRouter} from "react-router-dom";
import {FormContext, IFormContext} from "../FormContext";
import {FormattedMessage} from "react-intl";
import {
    QuestionnaireSummaryConditionGenerator
} from "../../../../util/questionnaire/claim/QuestionnaireSummaryConditionGenerator";
import jexl from "jexl";
import {DescriptionReveal} from "../panel/DescriptionReveal";
import {IFormComponentProps} from "../FormComponentBase";

interface IAdditionalField {
    displayCondition?: string;
    term: string;
    definition: string;
}

interface IMetadata {
    questionnairePath: string;
    questionnaireAnswersPath: string;
    questionnaireAnswersDisplayConditionPath: string;
    classNames?: any;
    spacingTop?: 'sm' | 'md' | 'lg' | '0' | '1' | '2' | '3' | '4' | '5';
    spacingBottom?: 'sm' | 'md' | 'lg' | '0' | '1' | '2' | '3' | '4' | '5';
    rowLimit?: number;
    redirectBtn?: {
        redirectText: string,
        redirectUrl: string,
        asBtn?: boolean
    };
    headerKeyValuePath?: { [key: string]: string };
    titleAsTemplate?: boolean;
    additionalFields?: IAdditionalField[];
    /**
     * Allows to specify range of what answers should be displayed.
     * If one sequenceNumber is provided, only one specified answer will be displayed
     */
    fieldRange?: [number, number] | [number]
}

interface IQuestionnaireSummaryState {
    readMore?: boolean;
    questionnaireAnswerRows: any[];
}

class QuestionnaireSummaryInternal extends OutputComponentBase<string, IMetadata, IQuestionnaireSummaryState> {
    public static contextType = FormContext;
    public context!: IFormContext;

    constructor(props: any) {
        super(props);
        this.state = {readMore: false, questionnaireAnswerRows: []};

        this.readMoreToggle = this.readMoreToggle.bind(this);
    }

    private readMoreToggle() {
        this.setState({readMore: !this.state.readMore});
    }

    public async componentDidMount() {
        const {questionnaireAnswersPath, questionnairePath} = this.metadata;

        const questionnaireData = get(this.context.data, questionnairePath);
        const questionnaireAnswers = get(this.context.data, questionnaireAnswersPath);

        await this.generateSummary(questionnaireData, questionnaireAnswers);
    }

    private filterByFieldRange(questionnaire: Questionnaire[]): Questionnaire[] {
        const {fieldRange} = this.metadata;

        if (Array.isArray(fieldRange)) {
            const [startField, endField] = fieldRange;
            return questionnaire.filter(({sequence}) => {
                const parsedSequence = Number(sequence)
                if (endField) {
                    return parsedSequence >= startField && parsedSequence <= endField;
                }

                return parsedSequence === startField;
            })
        }

        return questionnaire;
    }

    private sortAnswersBySequenceFromQuestionnaire(questionnaire: Questionnaire[], questionnareAnswers: any) {
        if (!Array.isArray(questionnareAnswers)) {
            return;
        }

        const withinRangeQuestionnaire = this.filterByFieldRange(questionnaire);
        // map sequences to questionnaireAnswers for sorting
        const questionnaireAnswersWithSequence = [];
        for (const [key, value] of questionnareAnswers) {
            const strippedKey = key.replace(/\D/g, '');
            const matchingQuestionnaire = withinRangeQuestionnaire.find(questionItem => Number(questionItem.lineId) === Number(strippedKey));
            if (matchingQuestionnaire) {
                questionnaireAnswersWithSequence.push([key, {sequence: matchingQuestionnaire.sequence, ...value}]);
            }
        }
        // sort questionnaireAnswers based on questionnaire sequence
        questionnaireAnswersWithSequence.sort((a, b) => {
            return Number(a[1].sequence) - Number(b[1].sequence);
        });
        // remove sequence keys
        const removedSequence = questionnaireAnswersWithSequence.map(questionItem => {
            delete questionItem[1]["sequence"];
            return questionItem;
        });
        return removedSequence as [string, IAnswer][];
    }

    private async generateSummary(questionnaire: Questionnaire[], questionnaireAnswers: QuestionsAnswers) {
        const answerComponents = [];
        const questionnaireSummaryGenerator = new QuestionnaireSummaryConditionGenerator(questionnaire, this.metadata.questionnaireAnswersDisplayConditionPath, {
            catalogName: this.context.journeyId,
            folderName: "claim"
        });

        if (!questionnaireAnswers) {
            return;
        }

        const orderedQuestionnaireAnswers = Object.entries(questionnaireAnswers)?.sort(([aKey, aVal], [bKey, bVal]) => questionnaire?.findIndex(questionA => questionA.lineId === Number(aKey.replace(/\D/g, ''))) - questionnaire?.findIndex(questionB => questionB.lineId === Number(bKey.replace(/\D/g, ''))));
        const orderedQuestionnaireAnswersBySequence = this.sortAnswersBySequenceFromQuestionnaire(questionnaire, orderedQuestionnaireAnswers)
        if (!orderedQuestionnaireAnswersBySequence) {
            return;
        }

        const displayConditions = questionnaireSummaryGenerator.createQuestionnaireDisplayConditions();

        for (const [key, value] of orderedQuestionnaireAnswersBySequence) {
            const strippedKey: string = key.replace(/\D/g, '');
            const question = questionnaire?.find(questionItem => Number(questionItem.lineId) === Number(strippedKey));

            const answerDefinition = questionnaireSummaryGenerator.getAnswerDefinition(question!, this.metadata.questionnaireAnswersPath, {
                key,
                questionnaireAnswerValue: value
            });

            const questionCondition = displayConditions?.find(condition => Number(condition.questionLineIdId) === Number(strippedKey))?.displayCondition;

            let conditionEval: boolean = true;
            if (questionCondition && questionCondition !== "") {
                conditionEval = await jexl.eval(questionCondition, this.context.data);
            }

            if (conditionEval) {
                answerComponents.push(this.prepareRow({question, answerDefinition}));
            }
        }

        if (this.metadata.additionalFields) {
            for (const additionalField of this.metadata.additionalFields) {
                let conditionEval = true;
                if (additionalField.displayCondition && additionalField.displayCondition !== "") {
                    conditionEval = await jexl.eval(additionalField.displayCondition, this.context.data);
                }

                if (conditionEval) {
                    answerComponents.push(this.prepareRow({
                        question: { questionTitle: <FormattedMessage id={additionalField.term} />},
                        answerDefinition: additionalField.definition
                    }));
                }
            }
        }

        this.setState({questionnaireAnswerRows: answerComponents});
    }

    prepareRow({question, answerDefinition}: any) {
        return (
            <DescriptionListResponsive.Row
                balanced={true}
                zebra={true}
            >
                <DescriptionList.Content>
                    <DescriptionListResponsive.Term>
                        {question?.questionTitle}
                    </DescriptionListResponsive.Term>
                    <DescriptionListResponsive.Definition>
                        <Template template={answerDefinition} data={this.context.data}/>
                    </DescriptionListResponsive.Definition>
                </DescriptionList.Content>
            </DescriptionListResponsive.Row>
        )
    }

    public render() {
        const {title} = this.schema;
        const {uiSchema} = this.props;
        const {
            redirectBtn, classNames, rowLimit,
            spacingTop,
            spacingBottom,
            headerKeyValuePath,
            titleAsTemplate
        } = this.metadata;

        const headerValues: { [key: string]: string } = {};

        if (!isEmpty(headerKeyValuePath)) {
            for (const [key, path] of Object.entries(headerKeyValuePath!)) {
                headerValues[key] = get(this.context.data, path, path);
            }
        };

        const reveal = (rowLimit ? <DescriptionReveal
                rowLimit={rowLimit}
                properties={this.state.questionnaireAnswerRows}
                readMore={this.state.readMore}
                readMoreToggle={this.readMoreToggle}
                renderComponents={true}
            />
            : this.state.questionnaireAnswerRows);

        const formattedTitle = <FormattedMessage id={title} values={headerValues}/>;
        const templateTitle = <Template template={title} data={this.context.data}/>;

        return this.state.questionnaireAnswerRows.length ? (
            <Spacing type={"margin"} top={spacingTop} bottom={spacingBottom}>
                <DescriptionListResponsive>
                    <div className={classNames}>
                        <SummaryCard customHeader={
                            title !== undefined && title !== "" &&
                            <SummaryCard.Header theme={5} noPaddingSide={true}>
                                <SummaryCard.HeaderText>
                                    {titleAsTemplate ? templateTitle : formattedTitle}
                                </SummaryCard.HeaderText>
                                <RedirectControl id={`${uiSchema.name}RedirectBtn`} context={this.context}
                                    redirectControl={redirectBtn} props={this.props} />
                            </SummaryCard.Header>
                        }>
                            <SummaryCard.Body>
                                {reveal}
                            </SummaryCard.Body>
                        </SummaryCard>
                    </div>
                </DescriptionListResponsive>
                {this.state.readMore && <div style={{paddingBottom: 44}}/>}
            </Spacing>
        ) : <></>;
    }
}

export const QuestionnaireSummary = withViewContext(withRouter<IFormComponentProps<string, IMetadata>, any>(QuestionnaireSummaryInternal));
