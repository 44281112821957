import * as React from "react";
import {AdditionalTimeCard as AdditionalTimeCardFUI, Button, Grid, withViewContext} from "@folksam-digital/ui";
import {CmsContext, ICmsContext} from "../../../../cms";
import {FormContext, IFormContext} from "../FormContext";
import {FormattedMessage, injectIntl} from "react-intl";
import {CmsHelper} from "../../../../Helpers/cms/CmsHelper";
import {InputComponentBase} from "./InputComponentBase";
import {FormFieldLayout} from "../../../FormFieldLayout";
import FormattedMarkdown from "../../../FormattedMarkdown";
import {SelectedCover, REGEXP} from "@folksam-digital/model";
import CurrencyInput from "react-currency-input-field";
import {CurrencyFieldAdapter} from "../adapter/CurrencyFieldAdapter";
import {
    isValidFormat,
    isValidMinMaxValue,
    isValue
} from "../../../../Helpers/validationFunctions/validateSmallBoatHelper";
import {IBreakPoint} from "../../layout/helpers";
import {defaultBreakpoints} from "./helpers/breakpoints/defaultBreakpoints";

interface IMetadata {
    bodyText?: string;
    placeholder?: string;
    breakpoints?: IBreakPoint;
}

interface IAdditionalTimeCardState {
    showBottomContent: boolean;
    inputButtonDisabled: boolean;
    value: string;
    previousValidValue?: string;
    previousMonthlyPremium?: number;
}

class AdditionalTimeCardInternal extends InputComponentBase<SelectedCover, IMetadata, IAdditionalTimeCardState> {
    public static contextType = FormContext;
    context!: IFormContext;

    public constructor(props: any, context: IFormContext) {
        super(props, context);

        this.onChecked = this.onChecked.bind(this);
        this.onInputChange = this.onInputChange.bind(this);
        this.onInputBlur = this.onInputBlur.bind(this);
        this.onInputButtonClick = this.onInputButtonClick.bind(this);
        this.onUnSelectCover = this.onUnSelectCover.bind(this);

        this.state = {
            value: "",
            showBottomContent: false,
            inputButtonDisabled: true
        }
    }

    public componentDidMount(): void {
        const {inputValue, monthlyPremium} = this.context.data.cover;

        if (inputValue) {
            this.setState({
                showBottomContent: !!(inputValue && monthlyPremium),
                value: String(inputValue),
                previousValidValue: String(inputValue),
                previousMonthlyPremium: monthlyPremium
            });
        }
    }

    public render() {
        const {cover: formData} = this.context.data;
        const {name,  intl} = this.props;
        const {baseMinValue, addonMaxValue} = this.context.data.premium;
        const {bodyText, placeholder, breakpoints} = this.metadata;

        const cardButton = (prefix: string) => (<Button
            id={`${name}Button`}
            type="button"
            disabled={this.context.disableButton || !!formData?.selected || this.state.inputButtonDisabled}
            onClick={this.onInputButtonClick}
            isBusy={this.context.disableButton}
            small={true}
            outline
            full
        >
            <FormattedMessage id={`${prefix}.time.timeCard.input.button.title`}/>
        </Button>);

        const cardInput = (<CurrencyInput
            id={`${name}Input`}
            name={`${name}Input`}
            customInput={CurrencyFieldAdapter}
            allowDecimals={false}
            forwardmetadata={
                {
                    placeholder: placeholder && intl.formatMessage({id: placeholder}, {...this.context.data.premium}),
                    suffix: <FormattedMessage id="general.price.suffix"/>,
                }
            }
            value={this.getInputValue()}
            forwardprops={{
                invalid: this.isInvalid(),
                onBlur: this.onInputBlur
            }}
            onValueChange={this.onInputChange}
        />);

        return (
            <CmsContext.Consumer>
                {(cmsContext: ICmsContext) => {
                    const prefix = CmsHelper.getPrefix(cmsContext);
                    return (
                        <Grid>
                            <Grid.Row>
                                <Grid.Col {...this.getBreakpoints(breakpoints)}>
                                    <AdditionalTimeCardFUI
                                        id={name}
                                        value={name}
                                        headerTextElement={<FormattedMarkdown messageKey={`${prefix}.time.timeCard.heading`}
                                                                              messageValue={this.context.data.premium}/>}
                                        image={CmsHelper.getImageAttributes(CmsHelper.withPrefix(cmsContext, "time.timeCard.headerImage"), cmsContext.catalog)}
                                        bodyTextElement={bodyText && <FormattedMarkdown
                                            messageKey={bodyText}
                                            messageValue={{
                                                incomeFloor: Number(baseMinValue),
                                                incomeCeiling: Number(addonMaxValue)
                                            }}
                                        />}
                                        showBottomContent={this.state.showBottomContent}
                                        bottomTextElement={<FormattedMarkdown
                                            messageKey={`${prefix}.time.timeCard.output.title`}
                                            messageValue={{
                                                incomeValue: !this.isInputValueSelectedAndValid(String(formData?.inputValue)) ? this.state.previousValidValue : formData?.inputValue,
                                                monthlyPremium: formData?.monthlyPremium
                                            }}/>}
                                        bottomPrice={<FormattedMarkdown
                                            messageKey={`${prefix}.time.timeCard.output.price`}
                                            messageValue={{monthlyPremium: formData?.monthlyPremium}}
                                        />}
                                        bottomButtonText={{
                                            unselected: <FormattedMessage
                                                id={"general.package.unselected"}/>,
                                            selected: <FormattedMessage
                                                id={"general.package.selected"}/>
                                        }}
                                        checked={!!(formData && formData?.selected)}
                                        onChecked={this.onChecked}
                                    >
                                        <FormFieldLayout {...this.getLayoutProps()}
                                                         style={{flex: 1}}>
                                            <Grid.Row style={{flexDirection: 'column'}}>
                                                <Grid.Col>{cardInput}</Grid.Col>
                                                <Grid.Col style={{marginTop: 12}}>{cardButton(prefix)}</Grid.Col>
                                            </Grid.Row>
                                        </FormFieldLayout>
                                    </AdditionalTimeCardFUI>
                                </Grid.Col>
                            </Grid.Row>
                        </Grid>
                )}}
            </CmsContext.Consumer>
        );
    }

    private isValidField(value: string): boolean {
        const {baseMinValue, addonMaxValue} = this.context.data.premium;

        if (!isValue(value) || !isValidFormat(value, REGEXP.DECIMAL_NUMBER!) || !isValidMinMaxValue(value, Number(baseMinValue), Number(addonMaxValue))) {
            return false
        }
        return true;
    }

    private getInputValue(): string {
        const {inputValue} = this.context.data.cover;
        // cases(no incomeValue, clears out value, values not match) when user reedits already calculated price
        if (!inputValue || (inputValue && !this.state.value) || (inputValue && this.state.value && inputValue !== this.state.value)) {
            return this.state.value;
        }
        return String(inputValue);
    }

    private isInputValueSelectedAndValid(value: string | undefined): boolean {
        if (this.state.previousValidValue && value === this.state.previousValidValue && this.isValidField(value || "")) {
            return true;
        }
        return false;
    }

    private onInputChange(tempValue?: string): void {
        const value = tempValue ? tempValue : "";

        // disable button when previous calculated value
        if (this.isValidField(value)) {
            this.setState({value: value || "", inputButtonDisabled: false});
        } else {
            this.setState({value: value || "", inputButtonDisabled: true});
        }

        this.onUnSelectCover();
    }

    private onInputBlur(): void {
        const data = this.context.data.cover as SelectedCover;
        const {baseMinValue, addonMaxValue} = this.context.data.premium;
        const {value} = this.state;

        if (Number(value) < Number(baseMinValue)) {
            data.inputValue = baseMinValue;
        } else if (Number(value) > Number(addonMaxValue)) {
            data.inputValue = addonMaxValue;
        } else {
            data.inputValue = value;
        }

        this.onInputChange(String(data.inputValue));
        this.props.onChange(data);
    }

    private onInputButtonClick(e: any): void {
        e.preventDefault();

        if (this.isValidField(this.getInputValue())) {
            const data = this.context.data.cover as SelectedCover;

            this.setState({
                inputButtonDisabled: true,
                showBottomContent: true,
                previousValidValue: String(data.inputValue),
                previousMonthlyPremium: data.monthlyPremium
            });

            this.props.onChange(data);
            this.context.updatePremium(e, 'click', undefined);
        }
    };

    private onChecked(event: React.ChangeEvent<HTMLInputElement>): any {
        if (this.isValidField(this.state.value)) {
            const newData = this.context.data.cover as SelectedCover;
            if (this.state.inputButtonDisabled) {
                newData.selected = !newData.selected;

                if (newData.selected) {
                    this.onChangeWithValidation(newData);
                } else {
                    this.props.onChange(newData);
                }
            } else {
                this.props.onChange(newData);
            }
        }
    }

    private onUnSelectCover() {
        const newData = this.context.data.cover;
        newData.selected = false;
        this.props.onChange(newData);
    }

    private getBreakpoints(breakpoints?: IBreakPoint): IBreakPoint {
        return {
            ...defaultBreakpoints.additionalTimeCard,
            ...breakpoints
        };
    }
}


const AdditionalTimeCard = injectIntl(withViewContext(AdditionalTimeCardInternal));
export {AdditionalTimeCard};
