import * as React from "react";
import {injectIntl, WrappedComponentProps} from "react-intl";
import {defaultTheme, FormInputCheckbox} from "@folksam-digital/ui";
import {FormFieldLayout} from "../../../FormFieldLayout";
import {IClassifierService} from "../../../../services";
import {ChoiceComponentBase, IChoiceComponentMetadataBase, IChoiceComponentStateBase} from "./ChoiceComponentBase";
import first from "lodash/first";
import flowRight from "lodash/flowRight";
import {IFormContext} from "../FormContext";
import {container} from "../../../../inversify.config";
import {Types} from "../../../../Types";
import {IFormComponentProps} from "../FormComponentBase";
import {ILocaleContext} from "../../../../intl/LocaleContext";
import {withLocaleContext} from "../withLocaleContext";
import get from "lodash/get";
import {TooltipOutput} from "../output/TooltipOutput";
import {Markdown} from "../../../Markdown";
import {MarkdownListTypes} from "@folksam-digital/model";
import injectSheet from "react-jss";
import classNames from "classnames";
import FormattedMarkdown from "../../../FormattedMarkdown";

const customLabelStyles = ["boldLabel"] as const;
type customLabelStylesType = typeof customLabelStyles[number];

interface IMetadata extends IChoiceComponentMetadataBase {
    classNames?: string;
    help?: string;
    helpPositionBottom?: boolean;
    disablePaths?: string[];
    messageDataPath?: string;
    isToggleVariation?: boolean;
    tooltip?: string;
    markdown?: string;
    customLabelStyle?: customLabelStylesType;
    labelLink?: {
        urlMessageKey: string;
        dynamicUrlValueModelPath?: string;
    };
}

interface ICheckBoxInternalProps<TValue, TMetadata> extends IFormComponentProps<TValue, TMetadata>, WrappedComponentProps {
    localeContext: ILocaleContext;
    classes?: any;
}

interface ICheckBoxState extends IChoiceComponentStateBase {

}

const cssStyles = {
    tooltip: {
        display: "inline-flex"
    },
    markdown: {
        paddingLeft: defaultTheme.padding.md,
    },
    markdownDisabled: {
        color: defaultTheme.colors.senary2,
        paddingLeft: defaultTheme.padding.md,
    },
    markdownError: {
        color: defaultTheme.colors.tertiary2,
        paddingLeft: defaultTheme.padding.md,
    },
    boldLabel: {
        fontWeight: defaultTheme.fontWeights.bold,
        color: defaultTheme.colors.black
    },
    invalidBoldLabel: {
        fontWeight: defaultTheme.fontWeights.bold,
    }
}

function capitalizeFirstLetter(name: string) {
    return name.charAt(0).toUpperCase() + name.slice(1);
}

class CheckBoxInternal extends ChoiceComponentBase<boolean, IMetadata, ICheckBoxState, ICheckBoxInternalProps<boolean, IMetadata>> {
    protected classifierService: IClassifierService;

    constructor(props: ICheckBoxInternalProps<boolean, IMetadata>, context?: IFormContext) {
        super(props, context);
        this.classifierService = container.get<IClassifierService>(Types.ClassifierService);

        this.state = {
            values: []
        }
    }

    public render() {
        const {
            required,
            formData,
            name,
            intl,
            classes
        } = this.props;
        const record = first(this.state.values);
        const isInvalid = this.isInvalid();
        const {
            disablePaths,
            messageDataPath,
            isToggleVariation,
            tooltip,
            markdown,
            customLabelStyle,
            labelLink
        } = this.metadata;
        const labelTitle = this.metadata.classifierId ? record?.title : undefined;

        const disabled: boolean = disablePaths && disablePaths.length > 0 ? disablePaths.some(disabledPath => {
            return get(this.context.data, disabledPath, false);
        }) : false;

        let title;
        if (this.schema.title) {
            let values;
            if (messageDataPath) {
                values = get(this.context.data, messageDataPath);
            }
            title = intl.formatMessage({id: this.schema.title}, values);
        }

        if (labelLink && labelLink.urlMessageKey) {
            // Extracts url string from a message key and then embeds it into the Checkbox label text as a link
            const urlMessageKeyDynamicValue = labelLink.dynamicUrlValueModelPath ? get(this.context.data, labelLink.dynamicUrlValueModelPath) : "";
            const urlMessageKey = labelLink?.dynamicUrlValueModelPath ?
                `${labelLink.urlMessageKey}.${urlMessageKeyDynamicValue}` : labelLink.urlMessageKey;
            const url = intl.formatMessage({id: urlMessageKey});
            title = <FormattedMarkdown messageKey={this.schema.title} messageValue={{url}}/>;
        }
        const classNameList: {[key: string]: boolean} = {};

        if (customLabelStyle) {
            if (isInvalid) {
                classNameList[classes[`invalid${capitalizeFirstLetter(customLabelStyle)}`] as string] = true;
            } else {
                classNameList[classes[customLabelStyle] as string] = true;
            }
        }

        const formInputCheckBox = <FormInputCheckbox
            required={required}
            key={name}
            id={name}
            label={labelTitle || title}
            value={String(formData)}
            name={name}
            checked={formData}
            invalid={isInvalid}
            onChange={(event: any) => this.onChangeWithValidation(event.target.checked)}
            disabled={disabled}
            toggle={!!isToggleVariation}
            labelClasses={classNames(classNameList)}
        />;

        return (
            <>
                {(title || labelTitle || !!isToggleVariation) &&
                    <FormFieldLayout {...this.getLayoutProps()} checkBoxMargin={true} title={undefined}>
                        <>
                            {!tooltip && <div
                                className={classNames(classes.labelLink, {[classes.labelLinkError]: isInvalid && !!labelLink})}>{formInputCheckBox}</div>}

                            {tooltip &&
                                <div
                                    className={classNames(classes.tooltip, classes.labelLink, {[classes.labelLinkError]: isInvalid && !!labelLink})}>
                                    {formInputCheckBox}
                                    <TooltipOutput messageKey={tooltip}/>
                                </div>
                            }

                            {markdown &&
                                <div
                                    className={disabled ? classNames(classes.markdownDisabled) : isInvalid ? classNames(classes.markdownError) : classNames(classes.markdown)}>
                                    <Markdown listType={MarkdownListTypes.BULLET}
                                              source={this.props.intl.formatMessage({id: markdown})}/>
                                </div>
                            }
                        </>
                    </FormFieldLayout>}
            </>
        );
    }

    protected updateFormDataValue() {
    }
}

const CheckBox = flowRight(withLocaleContext, injectIntl, injectSheet(cssStyles, {inject: ["classes"]} as any))(CheckBoxInternal);
export {CheckBox};
