import * as React from "react";
import {FormattedMessage} from "react-intl";
import {FormLabel, FormNote} from "@folksam-digital/ui";
import {IPanelComponentBaseProps, PanelComponentBase} from "./PanelComponentBase";
import {TooltipOutput} from "../output/TooltipOutput";
import {findChildError} from "../../../../util/findChildError";

interface IMetadata {
    required: boolean;
    help?: string;
    tooltip?: string;
    classNames: string;
}

interface IState {
    invalid: boolean
}

export class LabelPanel extends PanelComponentBase<string, IMetadata, IState> {
    constructor(props: IPanelComponentBaseProps<string, IMetadata>) {
        super(props);
        this.state = {
            invalid: false
        }
    }

    public async componentDidUpdate(prevProps: IPanelComponentBaseProps<string, IMetadata>, prevState: IState): Promise<void> {
        const {required} = this.metadata;
        if (required) {
            this.setState({invalid: this.childrenHaveError()})
        }
    }

    public render() {
        const {
            schema,
        } = this.props;

        const title = schema.title;
        const {required, help, tooltip, classNames} = this.metadata;

        return (
            <div className={classNames}>
                <FormLabel required={required}
                           invalid={this.state.invalid}
                           optionalText={<FormattedMessage id={"general.optionalText"}/>}
                           tooltip={tooltip && <TooltipOutput messageKey={tooltip}/>}>
                    <FormattedMessage id={title}/>
                </FormLabel>
                {help && <FormNote><FormattedMessage id={help}/></FormNote>}
                {this.props.properties?.map(p => p.content)}
            </div>
        );
    }

    private childrenHaveError(): boolean {
        let hasError = false;
        for (const child of this.props.properties! as any) {
            if (hasError) {
                break;
            } else if(!child.hidden) {
                hasError = findChildError(child.content.props.errorSchema, child.content.props.schema);
            }
        }
        return hasError;
    }
}
